import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6f7f079a615effe56f27b059fb965ec8/e1596/rr-weekly-queries.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFrFFSFyiV//8QAHBAAAgICAwAAAAAAAAAAAAAAAQIAAwQREhQi/9oACAEBAAEFAq0KRsbkeo0IO7vMqJNf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/AQyf/8QAHBAAAgICAwAAAAAAAAAAAAAAAAECETFREjKh/9oACAEBAAY/AnUXLw5SlTejsjNIVZ2Js//EABsQAQADAQADAAAAAAAAAAAAAAEAESExQVFx/9oACAEBAAE/IWyh7cEvSp8MgPJPx8hsp232zbEBtZ//2gAMAwEAAgADAAAAEHMP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/ECGFt//EAB4QAQACAQQDAAAAAAAAAAAAAAERIQAxQVGRYXGh/9oACAEBAAE/EGbFAqRUS23OhiBTakD7WXbjBIQbJJ8xF2z5W0dYRN06AHC5Br8yVW2f/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "rr-weekly-wueries",
            "title": "rr-weekly-wueries",
            "src": "/static/6f7f079a615effe56f27b059fb965ec8/4b190/rr-weekly-queries.jpg",
            "srcSet": ["/static/6f7f079a615effe56f27b059fb965ec8/e07e9/rr-weekly-queries.jpg 200w", "/static/6f7f079a615effe56f27b059fb965ec8/066f9/rr-weekly-queries.jpg 400w", "/static/6f7f079a615effe56f27b059fb965ec8/4b190/rr-weekly-queries.jpg 800w", "/static/6f7f079a615effe56f27b059fb965ec8/e5166/rr-weekly-queries.jpg 1200w", "/static/6f7f079a615effe56f27b059fb965ec8/b17f8/rr-weekly-queries.jpg 1600w", "/static/6f7f079a615effe56f27b059fb965ec8/e1596/rr-weekly-queries.jpg 2048w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now for example you watch one of `}<a parentName="p" {...{
        "href": "aliabsaaal.com"
      }}>{`Ali Abdaal`}</a>{`'s videos. You get motivated and in that fire you decide to setup your own productivity system. You get a To-do List manager, a calendar and and tape it together and say that it is a good day's work. The next day you use it. Maybe you even use it another day. But at last on the third or the fourth day, your system is all cluttered. You don't know what is where and what you should do.`}</p>
    <p>{`This happens due to a entropy or chaos. But what is chaos I hear you ask what is entropy? Basically, entropy is the tendency of a system to fall apart or get cluttered. You make a To-do list management system and use it for Quick Capture, in the course of a few days, there are hundreds of rogue tasks which have been completed but haven't been checked off, there are tasks which are mega urgent but you can't find it. That is entropy.`}</p>
    <p>{`Weekly Reviews are a process which you can use to prevent this from happening. They are the guardians of your system. They prevent entropy. You can do these on any system, no matter if it isn't Roam Research. or todoist. Heck you can even do these with pen and paper. You just sit down on a day for about an hour and go through all of your system. Got a lot of rogue todos? Put them in a list. Got a lot of uncategorised tasks? Categorise them. Also, ask yourselves a few questions like "What went well this week?" or "What didn't go so well this week and how can I improve it next week?". These help you progress. If you maintain a journal, then go through all the entries for the week. Take notes on them too if you are into that. Now, lets get into how I do it using Roam Research.`}</p>
    <p>{`So I do maintain a journal and therefore, I also take notes on it. It gives you a lot of joy when you see what your a week younger self was thinking about. Now, I use one of Roam's most powerful features. Queries! It is the language that you talk to Roam with. Here is the query that I use`}</p>
    <pre><code parentName="pre" {...{}}>{`{{[[query]]: {and: {or: [[💭Journal]] [[🌄Morning Pages]] [[📰3-Minute Journal]]} {between: [[September 11th, 2020]] [[September 12th, 2020]]}}}}
`}</code></pre>
    <p>{`Now, don't get scared and let me explain. First of all the`}</p>
    <pre><code parentName="pre" {...{}}>{`{{[[query]]:
`}</code></pre>
    <p>{`part just tells Roam that there is a query coming up. It initialises it. Then we have`}</p>
    <pre><code parentName="pre" {...{}}>{`{and: [[Input-1]] [[Input-2]] [[Input-3]]...}
`}</code></pre>
    <p>{`This will only display stuff if it has all the criterion satisfied There is also the`}</p>
    <pre><code parentName="pre" {...{}}>{`{or: [[Input-1]] [[Input-2]] [[Input-3]]...}
`}</code></pre>
    <p>{`At last there is the`}</p>
    <pre><code parentName="pre" {...{}}>{`{between: [[Date1]] [[Date2]]}
`}</code></pre>
    <p>{`This takes in two inputs as Dates. It will basically get all the daily notes day pages between those two dates, incusive`}</p>
    <p>{`Here is one of my favourite abilities of queries, you can nest them!! You can have something like`}</p>
    <pre><code parentName="pre" {...{}}>{`{and: {or: [[Input-1]] [[Input-2]] [[Input-3]]...} {or: [[Input-4]] [[Input-5]] [[Input-6]]...}}
`}</code></pre>
    <p>{`Lets break this down. What do you think this will do? It will display all the blocks which have been tagged with or are nested within`}</p>
    <pre><code parentName="pre" {...{}}>{`([[Input-1]] or [[Input-2]] or [[Input-3]] or ...) and ([[Input-4]] [[Input-5]] [[Input-6]]...)
`}</code></pre>
    <p>{`Now that is cool!!`}</p>
    <p>{`So lets breakdown my query for the weekly review`}</p>
    <pre><code parentName="pre" {...{}}>{`{and: {or: } {between: }}
`}</code></pre>
    <p>{`Now this will go for anything that is in the `}<inlineCode parentName="p">{`{or: }`}</inlineCode>{` part AND is between the two dates`}</p>
    <p>{`Now what will be the answer to me weekly review query?`}</p>
    <pre><code parentName="pre" {...{}}>{`{{[[query]]: }} -- Initialises the query
{or: [[💭Journal]] [[🌄Morning Pages]] [[📰3-Minute Journal]]} -- Asks Roam to get a list of all the blocks linked to [[💭Journal]] or [[🌄Morning Pages]] or [[📰3-Minute Journal]]
{between [[Previous sunday's date]] [[Saturday's date]]} -- Gets everything from the previous sunday to saturday as I do my weekly reivews on Sundays
{and: {or: [[💭Journal]] [[🌄Morning Pages]] [[📰3-Minute Journal]]} {between: [[September 11th, 2020]] [[September 12th, 2020]]}} -- It displays everything that is at least linked to one of the journals AND is in between the date range
`}</code></pre>
    <p>{`Isn't this cool!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      